export function getDataType(value) {
    if (typeof value === 'string') {
        // Check if the string value represents a valid date
        if (
            !isNaN(Date.parse(value)) &&
            new Date(value).getFullYear() >= 1950 &&
            new Date(value).getFullYear() < 2100
        ) {
            return { type: 'date', value };
        }
        // Check if the string value represents a number
        if (/^\d+$/.test(value)) {
            return { type: 'number', value };
        }
        if (value === 'status') {
            return { type: 'status', value };
        }
        return { type: 'string', value };
    } else if (typeof value === 'boolean') {
        return { type: 'boolean', value };
    } else if (typeof value === 'number') {
        return { type: 'number', value };
    } else {
        return { type: 'unknown', value };
    }
}

const dynamicTimes = [
    { text: 'Vor einer Stunde', timedif: 1000 * 3600 },
    { text: 'Vor 12 Stunden', timedif: 1000 * 3600 * 12 },
    { text: 'Vor 24 Stunden', timedif: 1000 * 3600 * 24 },
    { text: 'Vor 3 Tagen', timedif: 1000 * 3600 * 24 * 3 },
    { text: 'Vor einer Woche', timedif: 1000 * 3600 * 24 * 7 },
    { text: 'Vor 30 Tagen', timedif: 1000 * 3600 * 24 * 30 },
    { text: 'Letztes Jahr', timedif: 1000 * 3600 * 24 * 365 },
];

export function parseFilterValue(value, dataType) {
    if (!value) return value;
    switch (dataType) {
        case 'string':
            return value;
        case 'number':
            return Number(value);
        case 'boolean':
            return value.toString().toLowerCase();
        case 'date':
            if (dynamicTimes.find((e) => e.text === value)) {
                const dynDate = Date.now() - dynamicTimes.find((e) => e.text === value).timedif;
                return dynDate;
            }
            return new Date(value);
        default:
            return value;
    }
}

export function processLeads(leads) {
    const questions = [];
    const flatLeads = [];
    for (const lead of leads) {
        const leadData = {
            status: lead.status,
            createdAt: lead.createdAt,
            __original: lead,
            leadHistory: lead.history.length,
            'Anzahl Dateien': lead.amountFilesUploaded,
        };
        for (const item in lead.funnelData.profile) {
            const data = lead.funnelData.profile[item];
            leadData[data.title] = data.value;
        }
        for (const [key, value] of Object.entries(leadData)) {
            const dataType = getDataType(value);
            const alreadyExisting = questions.find((e) => e.key === key);
            if (alreadyExisting) {
                if (alreadyExisting.type === 'unknown' && dataType.type !== 'unknown') {
                    alreadyExisting.type = dataType.type;
                }
            } else {
                questions.push({ key, type: dataType.type });
            }
        }
        flatLeads.push(leadData);
    }
    return { questions, flatLeads };
}

export function applyFilters(leads, filters) {
    const data = processLeads(leads);
    const filteredOutLeads = data.flatLeads.filter((lead) => {
        return filters.every((filter) => {
            const conditions = filter.conditions || [{
                question: filter.question,
                dataType: filter.dataType,
                operator: filter.operator,
                value: filter.value
            }];
            return conditions.every((condition) => {
                const value = parseFilterValue(lead[condition.question], condition.dataType);
                const filterValue =
                    condition.operator === 'enthält'
                        ? condition.value.toLowerCase()
                        : parseFilterValue(condition.value, condition.dataType);
                return evaluateCondition(value, filterValue, condition.operator);
            });
        });
    });
    return filteredOutLeads.map((lead) => lead.__original);
}

function evaluateCondition(value, filterValue, operator) {
    switch (operator) {
        case 'enthält nicht':
            return value ? !value.toLowerCase().includes(filterValue) : true;
        case 'entspricht nicht':
            return value !== filterValue;
        case 'entspricht':
            return value === filterValue;
        case 'größer als':
            return value > filterValue;
        case 'kleiner als':
            return value < filterValue;
        case 'enthält':
            return value ? value.toLowerCase().includes(filterValue) : false;
        default:
            return true;
    }
}