module.exports = function (rawDate) {
    const MONTH_NAMES = [
        " Jan",
        " Feb",
        " Mär",
        " Apr",
        " Mai",
        " Jun",
        " Jul",
        " Aug",
        " Sep",
        " Okt",
        " Nov",
        " Dez",
    ];

    function getFormattedDate(
        date,
        prefomattedDate = false,
        hideYear = false
    ) {
        const day = date.getDate();
        //if it is not this year then only write the number of the month
        const month = date.getFullYear() === new Date().getFullYear() ? MONTH_NAMES[date.getMonth()] : date.getMonth() + 1;
        //hide the first two digits of the year or 
        //hide it completely when the year is the current year
        const year = date.getFullYear() === new Date().getFullYear() ? "" : date.getFullYear().toString();
        const hours = date.getHours();
        let minutes = date.getMinutes();

        if (minutes < 10) {
            // Adding leading zero to minutes
            minutes = `0${minutes}`;
        }

        if (prefomattedDate) {
            // Today at 10:20
            // Yesterday at 10:20
            return `${prefomattedDate}, ${hours}:${minutes}`;
        }

        if (hideYear) {
            // 10. January at 10:20
            return `${day}. ${month}, ${hours}:${minutes}`;
        }

        const hoursofyear = `, ${hours}:${minutes}`

        // 10. January 2017. at 10:20
        return `${day}.${month}${year ? '.' : ''}${year}${!year ? hoursofyear : ''}`;
    }

    // --- Main function
    function timeAgo(dateParam) {
        if (!dateParam) {
            return null;
        }

        const date =
            typeof dateParam === "object" ? dateParam : new Date(dateParam);
        const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
        const today = new Date();
        const yesterday = new Date(today - DAY_IN_MS);
        const seconds = Math.round((today - date) / 1000);
        const minutes = Math.round(seconds / 60);
        const isToday = today.toDateString() === date.toDateString();
        const isYesterday = yesterday.toDateString() === date.toDateString();
        const isThisYear = today.getFullYear() === date.getFullYear();

        if (seconds < 5) {
            return "jetzt";
        } else if (seconds < 60) {
            return `vor ${seconds} s`;
        } else if (seconds < 90) {
            return "<1 min";
        } else if (minutes < 60) {
            return `vor ${minutes} min`;
        } else if (isToday) {
            return getFormattedDate(date, "Heute"); // Today at 10:20
        } else if (isYesterday) {
            return getFormattedDate(date, "Gestern"); // Yesterday at 10:20
        } else if (isThisYear) {
            return getFormattedDate(date, false, false); // 10. January at 10:20
        }

        return getFormattedDate(date); // 10. January 2017. at 10:20
    }

    const raw = new Date(rawDate);
    return timeAgo(raw);
}