<template>
    <span class="pa-0">{{ timeString }}</span>
</template>

<script>
const cleanDateFunction = require('../../../scripts/cleanDate');

export default {
    props: ['time'],
    data: () => ({
        timeString: '',
        interval: null,
    }),
    created() {
        //check if time is a valid date
        if (isNaN(Date.parse(this.time))) {
            this.timeString = this.time;
        }
        this.timeString = cleanDateFunction(this.time);
        this.updateTime();
    },
    destroyed() {
        //clearInterval(this.interval);
    },
    methods: {
        updateTime: function () {
            const th = this;

            th.interval = setInterval(function () {
                th.timeString = cleanDateFunction(th.time);
            }, 1000);
        },
    },
};
</script>

<style lang="scss" scoped></style>
