import { render, staticRenderFns } from "./SettingsItem.vue?vue&type=template&id=a57d4bc2&scoped=true"
import script from "./SettingsItem.vue?vue&type=script&lang=js"
export * from "./SettingsItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a57d4bc2",
  null
  
)

export default component.exports