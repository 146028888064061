export const portalBuilder = {
    namespaced: true,
    state: {
        portalTemplates: null,
        allPagesPreviews: [],
    },
    getters: {
        // all pages previews
        getPagesPreviews: (state) => state.allPagesPreviews,

        // portal templates
        getPortalTemplates: (state) => state.portalTemplates || [],
        getPortalTemplateById: (state) => (id) =>
            state.portalTemplates.find((portalTemplate) => portalTemplate._id === id),
    },
    mutations: {
        // all pages previews
        setAllPagesPreviews(state, allPagesPreviews) {
            state.allPagesPreviews = allPagesPreviews;
        },

        // portal templates
        addPortalTemplates(state, portalTemplates) {
            if (!portalTemplates) return;
            if (!state.portalTemplates) state.portalTemplates = [];
            state.portalTemplates = [...state.portalTemplates, ...portalTemplates];
        },
        updatePortalTemplate(state, portalTemplate) {
            if (!state.portalTemplates) return;
            const oldPortalTemplate = state.portalTemplates.find((p) => p._id === portalTemplate._id);
            if (!oldPortalTemplate) return;
            Object.assign(oldPortalTemplate, portalTemplate);
        },
        removePortalTemplate(state, id) {
            state.portalTemplates = state.portalTemplates.filter((p) => p._id !== id);
        },
    },
    actions: {
        // all pages previews

        async fetchAllPagesPreviews({ state, commit }) {
            if (state.allPagesPreviews.length) return;

            const { data } = await this._vm.$api.get('pages/page/preview/all');
            const { data: allPagesPreviews } = data;
            commit('setAllPagesPreviews', allPagesPreviews);
        },

        async reloadAllPagesPreviews({ commit }) {
            const { data } = await this._vm.$api.get('pages/page/preview/all');
             const { data: allPagesPreviews } = data;
            commit('setAllPagesPreviews', allPagesPreviews);
        },
        // portal templates
        async fetchPortalTemplates({ commit }) {
            if (this.state.portalBuilder.portalTemplates) return;

            const res = await this._vm.$api.get('portalTemplates');
            commit('addPortalTemplates', res.data.data);
        },
        async createPortalTemplate({ commit }, portalTemplate) {
            const res = await this._vm.$api.post('portalTemplates', portalTemplate);
            if (!res.data.success) return;

            commit('addPortalTemplates', [res.data.data]);
            return res.data.data;
        },
        async updatePortalTemplate({ commit }, portalTemplate) {
            const res = await this._vm.$api.patch(`portalTemplates/${portalTemplate._id}`, portalTemplate);
            if (!res.data.success) return;

            commit('updatePortalTemplate', res.data.data);
            return res.data.data;
        },
        async duplicatePortalTemplate({ commit }, id) {
            const res = await this._vm.$api.post(`portalTemplates/duplicate/${id}`);
            if (!res.data.success) return;

            commit('addPortalTemplates', [res.data.data]);
            return res.data.data;
        },
        async deletePortalTemplate({ commit }, id) {
            const res = await this._vm.$api.delete(`portalTemplates/${id}`);
            if (!res.data.success) return;

            commit('removePortalTemplate', res.data.data._id);
            return true;
        },
    },
};
