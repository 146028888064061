<template>
    <v-dialog
        :overlay-opacity="0.8"
        scrollable
        v-model="dialogValue"
        :width="width"
        transition="dialog-transition"
        :persistent="persistent"
        :fullscreen="mobileFullscreen"
        @click:outside="closeIfNotPersistent"
    >
        <template v-slot:activator="{ on }">
            <div class="d-inline" @click="open" :style="`width: ${activatorFullWidth ? '100%' : 'max-content'};`">
                <slot name="activate"></slot>
            </div>
        </template>

        <v-card class="karte" :class="{ 'rounded-lg': !mobileFullscreen }">
            <div v-if="header" class="header">
                <slot name="headerIcon">
                    <v-icon v-if="icon" class="iconBorder">{{ icon }}</v-icon>
                </slot>

                <slot name="headerTitle">
                    <div :class="{ noSubheader: !subtitle }">
                        <div class="title">{{ name }}</div>
                        <h3 class="subtitle">
                            {{ subtitle }}
                        </h3>
                    </div>
                </slot>

                <v-spacer></v-spacer>

                <slot name="headerTopRight"></slot>

                <v-btn :class="{ noSubheader: !subtitle }" icon @click="close" class="x-close">
                    <v-icon class="rotate_45_degrees_right_on_hover" style="margin-top: 3px; font-size: 50">
                        mdi-close
                    </v-icon>
                </v-btn>
            </div>

            <v-card-text class="black--text" style="margin-top: 15px">
                <slot></slot>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: 'Dialog',
        },
        subtitle: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        width: {
            type: [String, Number],
            default: '600',
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        mobileFullscreen: {
            type: Boolean,
            default: false,
        },
        activatorFullWidth: {
            type: Boolean,
            default: false,
        },
        header: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },

        value: {
            type: Boolean,
            default: null,
            required: false,
        },
    },
    components: {},
    data() {
        return {
            dialog: false,
        };
    },

    computed: {
        dialogValue: {
            get() {
                if (this.value === undefined || this.value === null) {
                    return this.dialog;
                }

                return this.value;
            },
            set(value) {
                if (this.value === undefined || this.value === null) {
                    return (this.dialog = value);
                }
                this.$emit('input', value);
            },
        },
    },

    methods: {
        open() {
            if (this.disabled) return;
            this.dialogValue = true;
            this.$emit('open');
        },
        close() {
            this.dialogValue = false;
            this.$emit('close');
        },
        closeDialog() {
            this.dialogValue = false;
        },

        closeIfNotPersistent() {
            if (!this.persistent) {
                this.dialogValue = false;
                this.$emit('close');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.iconBorder {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    border: 1px solid var(--gray-200, #eaecf0);
    background: var(--base-white, #fff);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    color: #344054;
}

.header {
    display: flex;
    padding: 24px 24px 0px 24px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.title {
    white-space: nowrap;
    align-self: stretch;
    color: var(--Gray-900, #101828);
    font-size: 18px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
}

.subtitle {
    color: var(--gray-600, #475467);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.rotate_45_degrees_right_on_hover:hover {
    transform: rotate(90deg);
}

.x-close {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
}

.rounded-corners {
    padding-top: 20px;
    border-radius: 25px;
}

.karte {
    overflow-y: auto;
    overflow-x: hidden;
    position: inherit;
}

.karte::-webkit-scrollbar-track {
    background: transparent;
}

.karte::-webkit-scrollbar-thumb {
    color: var(--v-primary-base);
    background: var(--v-primary-base);
    border: 0px;
}

.karte::-webkit-scrollbar {
    width: 0px;
}

@media screen and (max-width: 600px) {
}
</style>
