import Vue from 'vue';

const pageModule = {
    namespaced: true,
    state: {
        editMode: false,
        page: null,
        historyBackward: [],
        historyForward: [],
        changesInSections: false,
        changesInBaseData: false,
        changesInPageDefinition: false,
    },
    mutations: {
        resetHistory(state) {
            state.historyBackward = [_.cloneDeep(state.page)];
            state.historyForward = [];
            state.sectionChanges = false;
            state.changesInBaseData = false;
            state.changesInPageDefinition = false;
        },
        discardCurrentChangesAndResetPage(state) {
            //take 0 index of historyBackward and set it as the current page
            state.page = _.cloneDeep(state.historyBackward[0]);
            state.historyBackward = [_.cloneDeep(state.page)];
            state.historyForward = [];
            state.editMode = false;
            state.sectionChanges = false;
            state.changesInBaseData = false;
            state.changesInPageDefinition = false;
        },

        pushHistory(state) {
            state.historyForward = [];

            if (_.isEqual(state.historyBackward[state.historyBackward.length - 1], state.page)) return;
            state.historyBackward.push(_.cloneDeep(state.page));
            //console.log('pushing history', _.cloneDeep(state.historyBackward));
        },
        undo(state) {
            if (state.historyBackward.length <= 1) {
                return; // Prevents removing the last state
            }

            // Clone historyBackward and historyForward
            const hbCopy = _.cloneDeep(state.historyBackward);
            const hfCopy = _.cloneDeep(state.historyForward);

            // Move the last item from historyBackward to historyForward
            const lastState = hbCopy.pop();
            hfCopy.push(lastState);

            // Update state
            state.historyBackward = hbCopy;
            state.historyForward = hfCopy;
            state.page = _.cloneDeep(hbCopy[hbCopy.length - 1]); // Set page to the new last state
        },

        redo(state) {
            if (state.historyForward.length === 0) {
                return;
            }
            const hfCopy = _.cloneDeep(state.historyForward);
            state.page = hfCopy.pop();
            state.historyForward = hfCopy;
            const hbCopy = _.cloneDeep(state.historyBackward);
            hbCopy.push(_.cloneDeep(state.page));
            state.historyBackward = hbCopy;
        },

        setVariables(state, variables) {
            state.page.variables = variables;
        },

        addVariable(state, { name, value }) {
            state.page.variables.push({ name, value });
        },

        removeWidget(state, { widgetID }) {
            const page = _.cloneDeep(state.page);
            const pageDefinition = page.pageDefinition;
            const sections = pageDefinition.sections;
            for (let i = 0; i < sections.length; i++) {
                const section = sections[i];
                let widgets = section.widgets;
                for (let j = 0; j < widgets.length; j++) {
                    if (widgets[j] && widgets[j]._id === widgetID) {
                        //replace with null
                        widgets[j] = null;
                        continue;
                    }
                }
            }
            state.page = page;
        },

        removeVariable(state, name) {
            state.page.variables = state.page.variables.filter((v) => v.name !== name);
        },

        createUserInput(state, { ownerWidget, value }) {
            const existing = state.page.userInputs.find((input) => input.ownerWidget === ownerWidget);
            if (existing) {
                existing.value = value;
                state.page.userInputs = [...state.page.userInputs];
                return;
            }

            state.page.userInputs.push({ ownerWidget, value });
        },

        setEditMode(state, editMode) {
            state.editMode = editMode;
        },

        setChangesInSectionsData(state, value) {
            state.sectionChanges = value;
        },

        setChangesInBaseData(state, value) {
            state.changesInBaseData = value;
        },

        setChangesInPageDefinition(state, value) {
            state.changesInPageDefinition = value;
        },

        resetChanges(state) {
            state.sectionChanges = false;
            state.changesInBaseData = false;
            state.changesInPageDefinition = false;
        },

        setPage(state, page) {
            state.page = page;
            state.sectionChanges = false;
            state.changesInBaseData = false;
            state.changesInPageDefinition = false;
            //syncVariablesInPage(state.page);
        },
        resetStore(state) {
            state.page = null;
            state.editMode = false;
            state.historyBackward = [];
            state.historyForward = [];
            state.sectionChanges = false;
            state.changesInBaseData = false;
            state.changesInPageDefinition = false;
        },
        setWidgetUserInput(state, { widgetId, value }) {
            const input = state.page.userInputs.find((input) => input.ownerWidget === widgetId);
            if (input) {
                input.value = value;
            } else {
                state.page.userInputs.push({ ownerWidget: widgetId, value });
            }
        },
    },
    actions: {
        async loadPageById({ commit }, pageId) {
            const { data } = await Vue.prototype.$api.get(`pages/page/${pageId}`);

            commit('setPage', data);
        },

        async reloadPage({ commit, state }) {
            const { data } = await Vue.prototype.$api.get(`pages/page/${state.page._id}`);
            commit('setPage', data);
        },
    },
    getters: {
        getSectionOfWidget: (state) => (widgetId) => {
            const page = state.page;
            const pageDefinition = page.pageDefinition;
            const sections = pageDefinition.sections;
            for (let i = 0; i < sections.length; i++) {
                const section = sections[i];
                let widgets = section.widgets;
                for (let j = 0; j < widgets.length; j++) {
                    if (widgets[j] && widgets[j]._id === widgetId) {
                        return section;
                    }
                }
            }
            return null;
        },

        getVariables: (state) => state.page?.variables || [],
        editMode: (state) => state.editMode,
        page: (state) => state.page,
        getWidgetUserInput: (state) => (widgetId) => {
            const input = state.page.userInputs.find((input) => input.ownerWidget === widgetId);
            return input ? input.value : null;
        },
        historyState: (state) => {
            return {
                canUndo: state.historyBackward.length > 1 && !!state.historyBackward[state.historyBackward.length - 1],
                canRedo: state.historyForward.length >= 1 && !!state.historyBackward[state.historyBackward.length - 1],
                forwardLength: state.historyForward.length,
                backwardLength: state.historyBackward.length,
                changes: state.historyBackward.length - 1,
                sectionChanges: state.sectionChanges,
                changesInBaseData: state.changesInBaseData,
                changesInPageDefinition: state.changesInPageDefinition,
            };
        },
    },
};

export default pageModule;
