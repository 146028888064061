<template>
    <div :class="cssClass" class="customBadge" :style="cssStyle" v-bind="$attrs" v-on="$listeners">
        <v-icon v-if="iconLeft" class="custombadge__icon-left" :size="iconSize">{{ iconLeft }}</v-icon>
        <div v-if="dotLeft" class="custombadge__dot" :style="cssStyleDot"></div>

        <div class="custombadge__text">
            <slot></slot>
        </div>

        <div v-if="dotRight" class="custombadge__dot" :style="cssStyleDot"></div>
        <v-icon v-if="iconRight" class="custombadge__icon-right" :size="iconSize">{{ iconRight }}</v-icon>
    </div>
</template>

<script>
import { getShade, getShades } from '@/scripts/getStatusColor';

export default {
    name: 'CustomBadge',
    props: {
        type: {
            type: String,
            default: 'badge', // badge | pill
        },
        size: {
            type: String,
            default: 'medium', // small | medium | large
        },
        color: {
            type: String,
            default: 'green',
        },
        dotLeft: {
            type: Boolean,
            default: false,
        },
        dotRight: {
            type: Boolean,
            default: false,
        },
        iconLeft: {
            type: String,
            required: false,
        },
        iconRight: {
            type: String,
            required: false,
        },
        maxWidth: {
            type: Number,
            required: false,
        },
        minWidth: {
            type: Number,
            required: false,
        },
        isClickable: {
            type: Boolean,
            default: false,
        },
        isPulsing: {
            type: Boolean,
            default: false,
        },
        modifier: {
            type: String,
            required: false,
        },
    },
    computed: {
        cssClass() {
            return {
                custombadge: true,
                [`custombadge--${this.type}`]: !!this.type,
                [`custombadge--${this.size}`]: !!this.size,
                [`custombadge--${this.color}`]: !!this.color,
                [`custombadge--${this.modifier}`]: !!this.modifier,
                [`custombadge--clickable`]: this.isClickable,
                [`custombadge--pulse`]: this.isPulsing,
            };
        },
        cssStyle() {
            let stylesFromHex;

            const isHexColor = this.color.startsWith('#');
            if (isHexColor) {
                stylesFromHex = {
                    'background-color': getShade(this.color, 0.7),
                    border: `solid 1px ${this.color}`,
                    color: getShades(this.color).darken,
                };
            }

            const styleObj = {
                maxWidth: this.maxWidth ? `${this.maxWidth}px` : null,
                minWidth: this.minWidth ? `${this.minWidth}px` : null,
                ...stylesFromHex,
            };
            return styleObj;
        },
        cssStyleDot() {
            return {
                'background-color': this.color,
            };
        },
        iconSize() {
            switch (this.size) {
                case 'small':
                    return 12;
                case 'medium':
                    return 14;
                case 'large':
                    return 18;
                case 'extralarge':
                    return 18;
            }
        },
    },
    methods: {
        getShade,
        getShades,
    },
};
</script>

<style scoped lang="scss">
.custombadge {
    --custombadge-pulse-color: #e0f7fa;
    --custombadge-pulse-size-start: 1px;
    --custombadge-pulse-size-end: 4px;

    $self: &;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-weight: 500;
    transition: 0.2s;

    &__text {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__dot {
        border-radius: 999px;
        background: currentColor;
        flex-shrink: 0;
    }

    &__icon-left,
    &__icon-right {
        color: inherit;
    }

    // types
    &--badge {
        border-radius: 6px;
    }

    &--pill {
        border-radius: 999px;
    }

    // sizes
    &--small {
        gap: 4px;
        font-size: 12px;
        padding: 4px 8px;

        #{ $self }__dot {
            width: 8px;
            height: 8px;
        }
    }

    &--medium {
        gap: 6px;
        font-size: 12px;
        padding: 6px 10px;

        #{ $self }__dot {
            width: 8px;
            height: 8px;
        }
    }

    &--large {
        gap: 8px;
        font-size: 14px;
        padding: 8px 12px;
    }

    &--extralarge {
        gap: 8px;
        font-size: 14px;
        padding: 10px 14px;
    }

    // other
    &--clickable {
        cursor: pointer;

        &:hover {
            filter: contrast(90%);
        }
    }

    // Untitled UI colors
    &--white {
        color: var(--Gray-700, #344054);
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: var(--Base-White, #fff);
        --custombadge-pulse-color: #d0d5dd;
    }

    &--gray {
        color: #344054;
        border: 1px solid var(--Gray-200, #eaecf0);
        background: var(--Gray-50, #f9fafb);
        --custombadge-pulse-color: #eaecf0;
    }

    &--purple {
        color: #573bc5;
        border: 1px solid var(--Primary-200, #d9d6fe);
        background: var(--Primary-50, #f4f3ff);
        --custombadge-pulse-color: #d9d6fe;
    }

    &--red {
        color: #b42318;
        border: 1px solid var(--Error-200, #fecdca);
        background: var(--Error-50, #fef3f2);
        --custombadge-pulse-color: #fecdca;
    }

    &--yellow {
        color: #b54708;
        border: 1px solid var(--Warning-200, #fedf89);
        background: var(--Warning-50, #fffaeb);
        --custombadge-pulse-color: #fedf89;
    }

    &--green {
        color: #067647;
        border: 1px solid var(--Success-200, #abefc6);
        background: var(--Success-50, #ecfdf3);
        --custombadge-pulse-color: #abefc6;
    }

    // other 'colors' :)
    &--campaign {
        color: var(--Gray-700, #344054);
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: var(--Gray-50, #f9fafb);
        --custombadge-pulse-color: #d0d5dd;
    }

    // other
    &--pulse {
        animation: custombadge-highlight 1s infinite alternate;
    }

    &--plus {
        &#{ $self }--small {
            padding: 4px;
        }
    }

    @keyframes custombadge-highlight {
        0% {
            box-shadow: 0 0 0 var(--custombadge-pulse-size-start) var(--custombadge-pulse-color);
        }

        100% {
            box-shadow: 0 0 0 var(--custombadge-pulse-size-end) var(--custombadge-pulse-color);
        }
    }
}
</style>
