<template>
	<div class="my-2 mb-5">
		<h3 class="mb-1 mt-2">{{ name }}</h3>
		<div class="mb-3">
			<span class="grey--text text--darken-1"
				><slot name="description" class="my-1"></slot
			></span>
		</div>
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: ["name"],
};
</script>

<style lang="scss" scoped>
</style>