/**
 * Calculates the color based on the given parameters.
 *
 * @param {number} p - The percentage value between -1 and 1.
 * @param {string} c0 - The initial color value in either 'rgb' or '#rrggbb' format.
 * @param {string} c1 - The final color value in either 'rgb' or '#rrggbb' format.
 * @param {boolean} l - Determines whether to use linear interpolation (true) or square root interpolation (false).
 * @returns {string|null} - The calculated color value in either 'rgb' or '#rrggbb' format, or null if the input is invalid.
 */
function colorFunction(p, c0, c1, l) {
    let r,
        g,
        b,
        P,
        f,
        t,
        h,
        i = parseInt,
        m = Math.round,
        a = typeof c1 == 'string';
    if (
        typeof p != 'number' ||
        p < -1 ||
        p > 1 ||
        typeof c0 != 'string' ||
        (c0[0] != 'r' && c0[0] != '#') ||
        (c1 && !a)
    )
        return null;
    if (!this.pSBCr)
        this.pSBCr = (d) => {
            let n = d.length,
                x = {};
            if (n > 9) {
                ([r, g, b, a] = d = d.split(',')), (n = d.length);
                if (n < 3 || n > 4) return null;
                (x.r = i(r[3] == 'a' ? r.slice(5) : r.slice(4))),
                    (x.g = i(g)),
                    (x.b = i(b)),
                    (x.a = a ? parseFloat(a) : -1);
            } else {
                if (n == 8 || n == 6 || n < 4) return null;
                if (n < 6) d = '#' + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : '');
                d = i(d.slice(1), 16);
                if (n == 9 || n == 5)
                    (x.r = (d >> 24) & 255),
                        (x.g = (d >> 16) & 255),
                        (x.b = (d >> 8) & 255),
                        (x.a = m((d & 255) / 0.255) / 1000);
                else (x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1);
            }
            return x;
        };
    (h = c0.length > 9),
        (h = a ? (c1.length > 9 ? true : c1 == 'c' ? !h : false) : h),
        (f = pSBCr(c0)),
        (P = p < 0),
        (t = c1 && c1 != 'c' ? pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }),
        (p = P ? p * -1 : p),
        (P = 1 - p);
    if (!f || !t) return null;
    if (l) (r = m(P * f.r + p * t.r)), (g = m(P * f.g + p * t.g)), (b = m(P * f.b + p * t.b));
    else
        (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
            (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
            (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
    (a = f.a), (t = t.a), (f = a >= 0 || t >= 0), (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
    if (h) return 'rgb' + (f ? 'a(' : '(') + r + ',' + g + ',' + b + (f ? ',' + m(a * 1000) / 1000 : '') + ')';
    else
        return (
            '#' +
            (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
                .toString(16)
                .slice(1, f ? undefined : -2)
        );
}


/**
 * Returns an object containing shades of a given color based on the status name and table.
 *
 * @param {string} statusName - The name of the status.
 * @param {object} table - The table object containing status information.
 * @returns {object} - An object containing shades of the original color.
 * @property {string} original - The original color.
 * @property {string} lighten - The lightened color.
 * @property {string} darken - The darkened color.
 * @property {string} verylight - The very light color.
 * @property {string} supermegalight - The super mega light color.
 */
function getStatusColor(statusName, table) {
    const status = table.status.find((s) => s.title === statusName);
    const colOrg = status ? status.color : '#555555';
    const lightColor = colorFunction(0.5, colOrg);
    const verylightColor = colorFunction(0.8, colOrg);
    const heavyColor = colorFunction(-0.5, colOrg);
    const supermegalight = colorFunction(0.8, colOrg);
    return {
        original: colOrg,
        lighten: lightColor,
        darken: heavyColor,
        verylight: verylightColor,
        supermegalight: supermegalight,
    };
}
/**
 * Returns an object containing shades of a given color.
 *
 * @param {string} colOrg - The original color.
 * @returns {object} - An object containing shades of the original color.
 * @property {string} original - The original color.
 * @property {string} lighten - The lightened color.
 * @property {string} verylight - The very light color.
 * @property {string} supermegalight - The super mega light color.
 * @property {string} heavy - The darkened color.
 * @property {string} inter - The intermediate color.
 */
function getShades(colOrg) {
    const lightColor = colorFunction(0.5, colOrg);
    const verylightColor = colorFunction(0.9, colOrg);
    const supermegalight = colorFunction(0.8, colOrg);
    const heavyColor = colorFunction(-0.5, colOrg);
    const inter = colorFunction(0.7, colOrg);
    return {
        original: colOrg,
        lighten: lightColor,
        darken: heavyColor,
        verylight: verylightColor,
        inter: inter,
        supermegalight: supermegalight,
    };
}



/**
 * Returns the shade of a given color with the specified opacity.
 *
 * @param {string} colOrg - The original color.
 * @param {number} opacity - The opacity value (between 0 and 1).
 * @returns {string} The shaded color.
 */
function getShade(colOrg, opacity) {
    return colorFunction(opacity, colOrg);
}

//export color function
module.exports = {
    getStatusColor: getStatusColor,
    colorFunction: colorFunction,
    getShades: getShades,
    getShade: getShade,
};
