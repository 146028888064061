<template>
    <v-app id="app" :style="getStyles" v-if="!!$api">
        <GlobalSnackbar></GlobalSnackbar>
        <GlobalAutomationAbort></GlobalAutomationAbort>
        <router-view></router-view>
    </v-app>
</template>

<script>
import GlobalSnackbar from './views/GlobalSnackbar.vue';
import GlobalAutomationAbort from './views/GlobalAutomationAbort.vue';
import { loadFont } from './configs/fontLoader.js';

export default {
    name: 'Home',
    data() {
        return {
            bodyFontFamily: 'Inter',
        };
    },
    async mounted() {
        // Check for token and track user if present
        const item = localStorage.getItem('token');
        if (item === null || item === '') {
            return;
        }
        const token = JSON.parse(localStorage.getItem('token'));
        if (token !== '') {
            await this.$store.dispatch('trackUser');
        }
    },
    created() {
        this.setDrawer(false);
    },
    computed: {
        style() {
            return this.$store.getters.style();
        },

        getStyles() {
            const style = this.style;
            if (!style) {
                return '';
            }
            const font = style.companyFont || 'Inter';
            loadFont(font.trim());
            return `font-family: ${font}, Arial, sans-serif;`;
        },
    },
    methods: {
        setDrawer(bool) {
            this.$store.commit('customer/SET_DRAWER', bool);
        },
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown; // Mobile and tablet devices
        },
        handleSwipeLeft() {
            if (this.isMobile()) {
                this.setDrawer(false);
            }
        },
        handleSwipeRight() {
            if (this.isMobile()) {
                this.setDrawer(true);
            }
        },
    },
    components: { GlobalSnackbar, GlobalAutomationAbort },
};
</script>

<style scoped>
#app {
    background-color: var(--v-background-base);
}

body {
    font-family: var(--font-family-body);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}
</style>
